/**
 * Locations Specific
 */

export default {
  TYPE_AIRPORT: 'AIRPORT',
  TYPE_RAIL: 'RAIL',
  TYPE_CITY: 'CITY',
  TYPE_PORT: 'PORT_OF_CALL',
  TYPE_BRANCH: 'BRANCH',
  TYPE_GEOLOCATION: 'GEO_LOCATION',
  BRAND_ENTERPRISE: 'ENTERPRISE',
  BRAND_NATIONAL: 'NATIONAL',
  BRAND_ALAMO: 'ALAMO',
  LOCATION_SEARCH_TYPES: {
    BOOKING_WIDGET: 'BOOKING_WIDGET',
    STANDALONE: 'STANDALONE',
    LOCATION_FINDER: 'LOCATION_FINDER',
  },
  AVAILABLE_LOCATIONS: 'AVAILABLE_LOCATIONS',
  UNAVAILABLE_LOCATIONS: 'UNAVAILABLE_LOCATIONS',
};
