import PROFILE from 'constants/profile';
import { selectedCountryDetails, isDialingCode } from 'utils/phoneInput';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import COUNTRY_CODE from 'constants/countryCode';
import { isMasked } from './validation';

/**
 * Compares PRP data code and users profile prefered program, if present, and returns if both programs are the same
 *
 * @param {object} profileData Authenticated user profile object, possibly with PRP data
 * @param {object} partnerRewardProgram Program complete data for a PRP page
 * @returns {boolean}
 */
export const profileProgramMatchPRP = (profileData, partnerRewardProgram) => {
  if (!profileData || !partnerRewardProgram) {
    return false;
  }
  const prpMatch = profileData?.partnerRewardsProgram?.code === partnerRewardProgram?.code;
  return !gmiUtils.isObjectEmpty(partnerRewardProgram) && prpMatch;
};

/**
 * Returns the complete phone object from a given phones array with the given priority default to 1 (primary)
 *
 * @param {array} phones Phones array from user's profile
 * @param {number} priority Priority value (1 primary / 2 alternate)
 * @returns {object} - Complete phone object
 */
export const getPhoneByPriority = (phones, priority = 1) => phones?.find((item) => item.priority === priority) || false;

/**
 * Format a passed in array of phone data and strip out special characters
 * @param phoneData
 * @returns {Array}
 */
export const formatPhoneData = (phoneData) =>
  Object.keys(phoneData)
    .filter((key) => phoneData[key].phone_number)
    .map((key, i) => {
      const item = phoneData[key];
      item.priority = i + 1;
      if (!isMasked(item.phone_number)) {
        item.phone_number = item.phone_number.replace(/\D/g, '');
      }
      if (!item.phone_type) {
        item.phone_type = PROFILE.PHONE_TYPE_HOME;
      }
      return item;
    });

/**
 * Returns the boolean value if the array of error codes contains GBO error code
 *
 * @param {object} DUPLICATE_EMAIL_ERROR_CODES Array of error codes
 * @returns {boolean}
 */
export const isDuplicateEmailError = (errorCode) => PROFILE.DUPLICATE_EMAIL_ERROR_CODES.includes(errorCode);

/**
 * Returns the boolean value if the array of error code & priority equals GBO error code & priority
 *
 * @param {object} CROS_LOGIN_ACCOUNT_ERROR Object of error code & priority
 * @returns {boolean}
 */
export const isCrosLoginAccountError = (errorCode, errorPriority) =>
  PROFILE.CROS_LOGIN_ACCOUNT_ERROR.CODE === errorCode && PROFILE.CROS_LOGIN_ACCOUNT_ERROR.PRIORITY === errorPriority;

/**
 * Unformats a USA/CA phone number.
 * @param {string} formattedNumber - The formatted phone number.
 * @returns {string} - The unformatted phone number.
 */
export const unformatPhoneNumber = (formattedNumber) => {
  const unformattedNumber = formattedNumber?.replace(/[^\d]/g, '');
  return unformattedNumber;
};

/**
 * Format a passed in array of phone data and strip out special characters
 * @param phoneData
 * @returns {Array}
 */
export const formatPhoneDataBasedOnCountryCode = (phoneData) =>
  Object.keys(phoneData)
    .filter((key) => phoneData[key].phone_number)
    .map((key, i) => {
      const item = phoneData[key];
      item.priority = i + 1;
      if (!isMasked(item.phone_number) && isDialingCode(item.phone_number?.trimEnd())) {
        return null;
      }
      if (!isMasked(item.phone_number)) {
        const formatPhone =
          item.phone_number?.split(' ')?.length > 1
            ? item.phone_number?.split(' ').slice(1).join(' ')
            : item.phone_number;

        item.phone_number = unformatPhoneNumber(formatPhone);
      }
      if (!item.phone_type) {
        item.phone_type = PROFILE.PHONE_TYPE_HOME;
      }
      // setting default country code based on the domain if the country details are not present
      if (
        Object.hasOwn(item, 'phone_number') &&
        item.phone_number.length > 0 &&
        item.phone_number !== item?.mask_phone_number &&
        !Object.hasOwn(item, 'country_code')
      ) {
        const getDialingCode = selectedCountryDetails.get();
        const { currentDialingCode = '', countryCode = '', countryName = '' } = getDialingCode;
        item.dialing_code = currentDialingCode;
        item.country_code = countryCode;
        item.country_name = countryName;
      }

      return item;
    });

/**
 * Formats a value as a USA/CA phone number.
 * @param {string} value - The value to format.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (value, selectedCountry, ignoreAddingCountryCode = false) => {
  const getDialingCode = selectedCountry || {};
  const { currentDialingCode = false, previouslySelectedDialingCode = false, countryCodesList = [] } = getDialingCode;
  const trimmedPhoneNumberEndValue = value && value.trimEnd();
  const valueSameAsDialingCode = countryCodesList?.some((item) => item === trimmedPhoneNumberEndValue);
  const prefixDialingCodeValue = currentDialingCode ? `${currentDialingCode || ''}${' '}` : null;
  const isNACountryDialingCode = [COUNTRY_CODE.US, COUNTRY_CODE.CA].includes(selectedCountry?.countryCode);

  // If the value is same has current dialingCode returing the selected dialing code
  if (valueSameAsDialingCode) {
    return prefixDialingCodeValue;
  }

  // If the value is masked phone number returning the same value of masked phone number
  if (
    trimmedPhoneNumberEndValue &&
    trimmedPhoneNumberEndValue?.length > 0 &&
    isMasked(trimmedPhoneNumberEndValue) &&
    ignoreAddingCountryCode
  ) {
    return value;
  }

  if (
    trimmedPhoneNumberEndValue &&
    trimmedPhoneNumberEndValue?.length > 0 &&
    trimmedPhoneNumberEndValue.length <= currentDialingCode.length
  ) {
    return `${currentDialingCode || ''}${' '}${trimmedPhoneNumberEndValue}`;
  }

  if (
    typeof value === 'string' &&
    Object.hasOwn(getDialingCode, 'currentDialingCode') &&
    trimmedPhoneNumberEndValue !== '' &&
    !isMasked(trimmedPhoneNumberEndValue) &&
    !valueSameAsDialingCode
  ) {
    const formatValue =
      trimmedPhoneNumberEndValue?.split(' ')?.length > 1
        ? trimmedPhoneNumberEndValue?.split(' ').slice(1).join(' ')
        : trimmedPhoneNumberEndValue;
    let formattedNumber = null;
    if (
      !isNACountryDialingCode &&
      trimmedPhoneNumberEndValue.length > currentDialingCode.length &&
      trimmedPhoneNumberEndValue !== previouslySelectedDialingCode
    ) {
      formattedNumber = unformatPhoneNumber(formatValue);
    }
    if (
      isNACountryDialingCode &&
      trimmedPhoneNumberEndValue.length > currentDialingCode.length &&
      trimmedPhoneNumberEndValue !== previouslySelectedDialingCode
    ) {
      const cleanedValue = formatValue.replace(/\D/g, '');
      const areaCode = cleanedValue.slice(0, 3);
      const centralOfficeCode = cleanedValue.slice(3, 6);
      const lineNumber = cleanedValue.slice(6, 10);
      formattedNumber = areaCode;

      if (areaCode && cleanedValue.length > 3) {
        formattedNumber = `(${areaCode})`;
      }

      if (centralOfficeCode) {
        formattedNumber += ` ${centralOfficeCode}`;
      }
      if (lineNumber) {
        formattedNumber += `-${lineNumber}`;
      }
    }

    if (ignoreAddingCountryCode) {
      return formattedNumber;
    }

    return `${prefixDialingCodeValue}${formattedNumber}`;
  }

  return prefixDialingCodeValue;
};
