import { createSelector } from 'reselect';
import utils from 'utils';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import REQUEST from 'constants/request';

const getGmaErrorMessages = (state, { statePath } = {}) =>
  statePath ? state.getIn(['gmi', 'messages', 'gma_services', statePath]) : state.getIn(['gmi', 'messages', 'gma']);
const getErrorCodes = (state, { errorCodes } = {}) => errorCodes;

export const gmaErrorMessagesSelector = createSelector(
  [getGmaErrorMessages, getErrorCodes],
  (gmaErrorMessages, errorCodes) =>
    utils.safeToJS(errorCodes ? gmaErrorMessages.filter(({ code }) => errorCodes.includes(code)) : gmaErrorMessages) ||
    []
);

export const hasGmaErrorMessagesSelector = createSelector(
  [gmaErrorMessagesSelector],
  (gmaErrorMessages) => !!gmaErrorMessages?.length
);

export const getReservationInitiateError = (state) =>
  utils.safeToJS(state.getIn(['gmi', 'messages', 'gma_services', 'reservations/initiate']), []);

export const isLocationWithNoVehiclesAvailableSelector = createSelector(
  [getReservationInitiateError],
  (messages) =>
    messages.filter(
      (message) =>
        message.code === GMA_RESPONSE_CODES.LOCATION_WITH_NO_VEHICLES_AVAILABLE ||
        message.code === GMA_RESPONSE_CODES.CROS_RES_VEHICLE_NOT_AVAILABLE
    ).length > 0
);

export const isPickupTimeInThePastSelector = createSelector(
  [getReservationInitiateError],
  (messages) =>
    messages.filter((message) => message.code === GMA_RESPONSE_CODES.INVALID_PICK_UP_TIME_IN_THE_PAST).length > 0
);

export const isStateAndProvinceEndpointErrorSelector = (state, selectedCountry) =>
  utils.safeToJS(
    state.getIn(['gmi', 'messages', 'gma_services', `location/countries/${selectedCountry}/stateAndProvince`]),
    []
  );

/*
 * @function hasInvalidEmailCode
 * @param  {object}  message message from service
 * @return {Boolean}
 */
export function hasInvalidEmailCode(message = {}) {
  return (
    message.code === REQUEST.MESSAGES.CODES.WRONG_FORMAT_EMAIL_ADDRESS ||
    message.code === REQUEST.MESSAGES.CODES.WRONG_FORMAT_EMAIL_ADDRESS_DVRPR_CODE
  );
}

/*
 * @function hasInvalidEmailCode
 * @param  {object}  message message from service
 * @return {Boolean}
 */
export function hasDuplicateEmailCode(message = {}) {
  return (
    message.code === REQUEST.MESSAGES.CODES.DUPLICATE_EMAIL_ADDRESS ||
    message.code === REQUEST.MESSAGES.CODES.DUPLICATE_EMAIL_ADDRESS_DVRPR_CODE
  );
}


/*
* @function hasGboPhoneInputInvalid
* @param {object} message message from service
* @return {Boolean}
*/
export function hasGboPhoneInputInvalidError(message = {}) {
  return message.code === REQUEST.MESSAGES.CODES.GBO_PHONE_INPUT_INVALID;
}


export const hasInvalidPhoneErrorSelector = createSelector([getGmaErrorMessages], (gmaErrormessages) => {

  return gmaErrormessages.some((message) => message.code === REQUEST.MESSAGES.CODES.GBO_PHONE_INPUT_INVALID);

})
