import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import {
  reduxEvent,
  determineType,
  analyticsInteraction,
  analyticsError,
  analyticsPrepareKey,
  mergeSolrIntoAnalytics,
  mergeSolrLocationInfoIntoAnalytics,
  mergeGmaIntoAnalytics,
} from 'utils/analytics';
import Storage from 'utils/storageManager';
import { config } from 'utils/config';
import { VEHICLE_PATH } from 'reducers/vehicleGrid';
import { getCurrentPageHash, getCurrentPagePath } from 'utils/url';
import ANALYTICS from 'constants/analytics';
import { SESSION_STORAGE } from 'constants/session';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';

const currentModalKeys = [];

/**
 * This util function is meant to examine the data before/after the changes to store and determine
 * what exactly has changed. Based off of the current state and the requested update (action data)
 * this will return an object of useful information allowing dispatch of analytics interactions.
 *
 * @param {Object} activeFilters - The current (pre-action) snapshot of state
 * @param {Object} payloadFilters - The action's payload with the new/updated state
 * @param {Object} vehicleFilters - The piece of Redux store that holds all of the filter data
 *
 * @return {object} An object of meaningful data that can be used to dispatch the correct analytics events
 * Here is an example of what the shape would look like:
 *  {
 *    category: 'Vehicle Class',
 *    label: 'Cars',
 *    itemAdded: true,
 *    itemRemoved: false,
 *    itemChanged: false,
 *  }
 */
const determineCarClassFilterProperties = (activeFilters = [], payloadFilters = [], vehicleFilters = []) => {
  const filterTransmission = activeFilters?.find((filter) => filter.keyPath === ANALYTICS.FILTERS_TRANSMISSION);
  const payloadFilter = payloadFilters?.map((filter) => {
    const filterValue = { ...filter };
    if (filterValue.keyPath === ANALYTICS.FILTERS_TRANSMISSION && filterValue.values.length === 0) {
      filterValue.values = [ANALYTICS.ALL_FILTER];
      return filterValue;
    }
    return filter;
  });
  // Create an array of all of the currently selected filters ("old state")
  // This will be an array of strings like ["100", "500"], these are transformed by a GMI util to a pretty string
  const activeFilterValues = activeFilters?.reduce(
    (acc, curr) => acc.concat(curr.available?.filter((f) => f.checked).map((f) => f.value)),
    []
  );

  // Create an array of all of the newly selected filters ("new state" from Action).
  // This will also be an array of strings like ["100", "500"], these are transformed by a GMI util to a pretty string
  const payloadFilterValues = payloadFilter?.reduce(
    (acc, curr) => (curr.radioValue ? acc.concat(curr.radioValue) : acc.concat(curr.values)),
    []
  );

  // Determine if an item is being added or removed (checked or unchecked...)
  const itemAdded = activeFilterValues.length < payloadFilterValues.length;
  const itemRemoved = !itemAdded;
  const itemChanged = activeFilterValues.length === payloadFilterValues.length;

  // Compare the New State with the Old State to determine what is different & return a singular string!
  const interactedValue =
    itemAdded || itemChanged
      ? payloadFilterValues?.find((filter) => !activeFilterValues.includes(filter)) ||
        payloadFilterValues?.findLast((filter) => activeFilterValues.includes(filter)) // Added || 'or' condition to handle change value for checkbox with radio flag
      : activeFilterValues?.find((filter) => !payloadFilterValues.includes(filter));

  // Determine which filter Category object holds the `interactedValue` -- we need this for the `category` name
  let interactedValueCategory;
  if (interactedValue === ANALYTICS.ALL_FILTER) {
    interactedValueCategory = filterTransmission;
  } else {
    interactedValueCategory = activeFilters?.find((category) =>
      category.available.find((filter) => filter.value === interactedValue)
    );
  }

  // To send Anlytics value, Get Label By Code from selected filter catogory group
  const vehicleFiltersInteractedCategory = vehicleFilters?.filter(
    (category) => category?.filter_description === interactedValueCategory?.category
  );

  // This prefix helps the analytics team know if the interaction was an addition, change or deletion
  let labelPrefix;
  if (interactedValue === ANALYTICS.ALL_FILTER) {
    labelPrefix = 'Added ';
  } else {
    labelPrefix = itemChanged ? 'Changed to ' : (itemRemoved && 'Removed ') || 'Added ';
  }

  let label;
  if (interactedValue === ANALYTICS.ALL_FILTER) {
    label = interactedValue;
  } else {
    label = vehicleFilters
      ? // the vehicle select UI has all the vehicles (`interactedValue`) in Redux
        gmiUtils.getVehicleSelectLabelByCode(vehicleFiltersInteractedCategory, interactedValue)
      : // map filters do not, so we need to scrape the `available` array to get our label string
        interactedValueCategory?.available?.find((option) => option.value === interactedValue).label;
  }

  return {
    category:
      (interactedValueCategory?.category?.includes('_') && i18n(interactedValueCategory?.category)) ||
      interactedValueCategory?.category,
    label: labelPrefix + label,
    itemAdded,
    itemRemoved,
    itemChanged,
  };
};

const getFeaturedVehicleSelectedState = (hasFoundRecommendedCarClass, selectedCarClassCode, session) => {
  let value = 'false';

  if (
    hasFoundRecommendedCarClass?.code === selectedCarClassCode ||
    session.gbo.reservation?.car_class_details?.vehicle_attribute === 'top'
  ) {
    value = 'true';
  }

  return value;
};
/**
 * The analytics Middleware is meant to intercept *all* redux actions and allow us to fire off
 * custom events based on the actions being dispatched. We intercept the actions and then
 * fire off `CustomEvent` bound to a DOM node (@see utils/analytics.js) -- we of course
 * _don't_ change/mutate any of the date (observe only!).
 *
 * This function works effectively as a large switch statement listening for specific actions
 * that we decide we want to trigger analytics events off of and ignoring all the other ones.
 * We have also added a generic `CustomEvent` that will broadcast _all_ actions to the `document`
 * object allowing for the analytics team to hook into the redux cycle directly.
 *
 */
export const analytics = (store) => (next) => (action) => {
  // Note: this destructured object represents many values that _can_ be in an action but likely _wont_
  // all be in a single action!
  const {
    analyticsKey,
    analyticsValue,
    filters,
    modalKey,
    path,
    resp,
    serviceType,
    session,
    skipAnalytics,
    statePath,
    storeKey,
    type,
    value,
    items,
  } = action;
  const filterCursor = ['gmi', 'ui', 'filters', storeKey];
  const modalCursor = ['app', 'location_finder', 'skipAnalytics'];
  const filterKey = determineType(storeKey);
  // These `let` vars are used within the switch below. They are defined here to work around our linting rules
  let lastIndexInPath;
  let activeFilters;
  let analyticsData;
  // Check the current values in store with these two vars
  let state;
  let existingValue;

  // Generically fire off an event for _all_ actions. This gives the analytics team access to all actions/redux
  // upates without any custome code...
  reduxEvent(action);
  switch (type) {
    /* GMI - parse set state paths to fire "custom events" */
    case ANALYTICS.SET_GMI_STATE:
      // This is used to determine what is updating in store (via GMI) -- look at the last index in the `path`...
      lastIndexInPath = path[path.length - 1];
      // Pickup/Drop date/time changes - keep in mind that date & time are _one_ value in store `*_time`
      if (
        [
          ANALYTICS.GMI_RETURN_TIME_NODE_NAME,
          ANALYTICS.GMI_PICKUP_TIME_NODE_NAME,
          ANALYTICS.GMI_RETURN_DATE_NODE_NAME,
          ANALYTICS.GMI_PICKUP_DATE_NODE_NAME,
        ].includes(lastIndexInPath)
      ) {
        const skipAnalyticsForPickAndReturnDateTime = sessionStorage.getItem(
          ANALYTICS.SKIP_ANALYTICS_FOR_SAVED_RESERVATION_SESSION_LDT_VALUES
        );
        const skipAnalyticsForDateTimeDrawer = sessionStorage.getItem(
          ANALYTICS.SKIP_ANALYTICS_FOR_DATE_TIME_DRAWER_ON_UNAVAILABLE_LOCATION_MODAL_SUBMIT
        );
        // Check store for existing value. GMI often dispatches the same event multiple times for "one change".
        // We only want to fire the analytics event once, so we compare the existing value in store with the new one.
        state = store.getState();
        existingValue = state?.getIn(path);

        if (value !== existingValue && !skipAnalyticsForPickAndReturnDateTime && !skipAnalyticsForDateTimeDrawer) {
          const currentPagePath = getCurrentPagePath();
          const currentPageHashName = currentPagePath?.split('/').pop();
          // Both the date and the time UI components update the same value in store, a generic "Time" node. This node is
          // {Date}T{Time}, so we want to split on the `T` and then compare the second value to determine if the date or the
          // time is changing. If there is no existing value (date or time), we then look at the first index (date).
          analyticsData = {
            key:
              (!existingValue && value?.split('T')[0]) || value?.split('T')[1] === existingValue?.split('T')[1]
                ? ANALYTICS.DATE
                : ANALYTICS.TIME,
          };

          // Checking for Rental/Reservation flow to push diff analytics object values accordingly
          const lastIndexInPathValue = lastIndexInPath.replace(/([a-z]+)_.*/i, `$1 ${analyticsData.key}`);
          const analyticsKeyValue =
            currentPageHashName === ANALYTICS.RENTALFLOW_PATH
              ? `${ANALYTICS.MODIFY} ${lastIndexInPathValue}`
              : ANALYTICS.RESERVATION;
          const analyticsTypeValue =
            currentPageHashName === ANALYTICS.RENTALFLOW_PATH ? ANALYTICS.UI_DROPDOWN_SELECT : lastIndexInPathValue;

          // Push the interaction to the Analytics object -- note: we use some regex trickery here to get "pickup/return" from the
          // "path" instead of needing to create more constants and boolean logic.
          analyticsInteraction(analyticsKeyValue, analyticsTypeValue, value);
        }
      }
      break;

    /* Start Interactions */
    // Filters
    case ANALYTICS.ACTION_FILTER:
      state = store.getState();
      activeFilters = state?.getIn([...filterCursor, 'filters'])?.toJS();

      if (filterKey === ANALYTICS.VEHICLE) {
        // Determine correct vehicle filters for GMI from either external vehicles page or res flow
        const vehicleFilters = state?.getIn([...VEHICLE_PATH, 'vehicleFilters'])
          ? state?.getIn([...VEHICLE_PATH, 'vehicleFilters']).map((filter) => ({
              filter_values: filter.filterValues, // Set to GMI snake case
            }))
          : state?.getIn(['gmi', 'session', 'gbo', 'reservation', 'car_classes_filters'])?.toJS();
        analyticsData = determineCarClassFilterProperties(activeFilters, filters, vehicleFilters);
        // Only 'Drive' is coming from GMI while Analytics team ask for 'Drive Type' for the key
        const isFilterDrive = activeFilters?.find((filter) => filter.keyPath === 'filters.DRIVE.filter_code');
        const categoryKey =
          analyticsData.category === isFilterDrive?.category
            ? i18n('vehicle_filter_drive_type')
            : analyticsData.category;
        analyticsInteraction(ANALYTICS.FILTER, categoryKey || filterKey, analyticsData.label);
      } else if (filterKey === ANALYTICS.LOCATION) {
        analyticsData = determineCarClassFilterProperties(activeFilters, filters, null);
        const isFilterVisible = state?.getIn([...filterCursor, 'filtersIsVisible']);
        let skipAnalyticsForApplyFilter = state?.getIn(modalCursor);
        skipAnalyticsForApplyFilter = !!skipAnalyticsForApplyFilter;
        // only send event if filter is visible
        isFilterVisible &&
          !skipAnalyticsForApplyFilter &&
          analyticsInteraction(ANALYTICS.FILTER, analyticsData.category || filterKey, analyticsData.label);
      }
      break;

    // Modals:
    // Note: All modal actions (from an analytics POV) do pretty much the same thing, hence them sharing one case scope.
    // keep in mind that the "clear queue" action is often called in place of "close" but can also be in addition to it...
    case ANALYTICS.ACTION_OPEN_MODAL:
    case ANALYTICS.ACTION_CLOSE_MODAL:
    case ANALYTICS.ACTION_CLEAR_MODAL_QUEUE:
      // If the `skipAnalytics` flag is set to `true` then we can skip all of this logic.
      if (!skipAnalytics) {
        // Allow for the "interaction" key and value to be overwritten by config. This object
        // holds a number of vars that will be used to allow for custom override values for
        // _analytics_ only!
        analyticsData = {
          isOpenAction: type === ANALYTICS.ACTION_OPEN_MODAL,
          key: analyticsPrepareKey(analyticsKey || modalKey || currentModalKeys.pop()),
          value: analyticsValue || (type === ANALYTICS.ACTION_OPEN_MODAL ? ANALYTICS.OPEN : ANALYTICS.CLOSE),
        };

        // When opening the modal we need to push the key into the current modal (tracking) array.
        if (analyticsData.isOpenAction) {
          currentModalKeys.push(modalKey);
        }

        // If there is no key, we dont fire the interactions... When you click the close button or "click outside"
        // the modal this fires both a close and clear queue action, this solves for that case.
        if (analyticsData.key) {
          analyticsInteraction(ANALYTICS.MODAL, analyticsData.key, analyticsData.value);
        }
      }
      break;
    /* End Interactions */

    /* Start Merges */
    // Solr
    case ANALYTICS.ACTION_REQUEST_SUCCESS:
      if (serviceType && serviceType.toLowerCase() === 'solr') {
        if (statePath.includes('spatial')) {
          mergeSolrIntoAnalytics({ branch_loc_locations: resp.result });
        }
      }
      break;

    /**
     * This middleware is used to add a new property to the `_analytics.solr`
     * object with the locations that are visible in screen.
     */
    case ANALYTICS.ACTION_COMPOSE_FILTERS:
      if (storeKey === 'location_finder') {
        mergeSolrLocationInfoIntoAnalytics({ visible_branch_locations: items });
      }
      break;

    // GMA
    case ANALYTICS.ACTION_UPDATE_SESSION:
      if (session && session.analytics) {
        // The rest of this logic is to determine if it's a "new page view" and need to fire the analytics ready event
        state = store.getState();

        // When a user navigates to the vehicle select page and sees a vehicle that has a feature message,
        // adding that value to the _analytics object as specified in time to go with the existing pageView (vehicleSelect/extras/confirmation).
        if (session?.analytics.gbo?.reservation) {
          const currentStepHash = getCurrentPageHash();
          const currentPagePath = getCurrentPagePath();
          const showFeaturedVehicle = config.getIsEnableFeaturedVehicle();
          const carClasses = session.gbo?.reservation?.car_classes;
          const selectedCarClassCode = session.gbo.reservation?.car_class_details?.selected_car_class_code;
          const { RESFLOW_PATHS_CONFIG } = ANALYTICS;
          const currentPageHashName = currentPagePath?.split('/').pop();
          const addReviewPageFeatureCarClass = Storage.SessionStorage.get(SESSION_STORAGE.ADD_FEATURED_CAR_CLASS, true);
          let hasFoundRecommendedCarClass = {};

          if (carClasses) {
            hasFoundRecommendedCarClass = carClasses?.find((item) => item.vehicle_attribute === 'top');
          }

          if (
            showFeaturedVehicle &&
            hasFoundRecommendedCarClass &&
            !gmiUtils.isObjectEmpty(carClasses) &&
            (RESFLOW_PATHS_CONFIG.includes(currentStepHash) ||
              RESFLOW_PATHS_CONFIG.includes(currentPageHashName) ||
              addReviewPageFeatureCarClass)
          ) {
            const feature_message_displayed = currentStepHash === RESFLOW_PATHS_CONFIG[0] ? 'true' : false;
            const car_class =
              currentStepHash === RESFLOW_PATHS_CONFIG[0] ? hasFoundRecommendedCarClass.code : selectedCarClassCode;
            const selected = getFeaturedVehicleSelectedState(
              hasFoundRecommendedCarClass,
              selectedCarClassCode,
              session
            );

            const reserved =
              currentPageHashName === WINDOW_OBJECT_KEYS.CONFIRMATION ||
              currentPageHashName === WINDOW_OBJECT_KEYS.CONFIRMATION_MODIFY
                ? 'true'
                : 'false';

            session.analytics.gbo.reservation.featured_car_class = {
              ...(feature_message_displayed && { feature_message_displayed }),
              ...(car_class && { car_class }),
              ...(selected && { selected }),
              ...(reserved && { reserved }),
            };
          }
        }
        // Always broadcast an event when the session is updated. This has to come _AFTER_ the `analytics.init`!
        mergeGmaIntoAnalytics(session.analytics);
      }
      break;
    /* End Merges */

    // Errors
    case ANALYTICS.ACTION_REQUEST_ERROR:
      // If this is a GBO style error, lets dispatch an error per index in response (likely only one)
      if (Array.isArray(action.messages) && action.messages.length) {
        action.messages.map((msg) => analyticsError(action.reqUrl, msg.code, msg.message));
      } else {
        // After G1 make sure payload is not capturing passwords or other potential PII
        analyticsError(action.reqUrl, action.messages, action.payload);
      }
      break;

    default:
      break;
  }

  return next(action);
};
