import {
    getUiInitiatePickupTime,
    getUiInitiateReturnTime,
    getUiOneWayRentalFlag,
    uiInitiateLocationsSelector
} from "selectors/uiStateSelectors";
import { getModifyFlag } from "selectors";
import RESERVATIONS from "constants/reservations";
import utils from "utils";
import {
    getInitiatePickupTime,
    getInitiateReturnTime,
    getOneWayRentalResFlow,
    gmaInitiateLocationsSelector
} from "selectors/reservationSelectors";
import LOCATIONS from "constants/locations";
import { gmiRequest } from "actions/request";
import { setRecentSearchItem } from "actions/locationSearch";
import { locationFinderDataSelector } from "selectors/appSelectors";
import { getLocationsAvailability } from "actions/locationFinder";
import { setProgressBarLocationInitiateState } from "actions/progressBar";
import { fetchCurrentSession } from "actions/session";
import { SESSION_STORAGE } from "constants";
import { actions } from "@ehi/global-marketing-interface";

const {
    initiate,
    initiateState,
} = actions.reservations;

export const initiateReservationFlow =
    (options = {}) =>
        (dispatch, getState) => {
            // Reset sold out location value on every initiate
            sessionStorage.setItem('soldOutLocation', false);
            sessionStorage.setItem('isSelectedInResOCISaveTime', null);
            const state = getState();
            const { uiPickupLocation, uiReturnLocation } = uiInitiateLocationsSelector(state);
            const isTrueModify = getModifyFlag(state);
            let shouldUpdateAvailability = false;

            const { location } = RESERVATIONS.RESFLOW_PATHS_CONFIG;
            const currentStepHash = utils.url.getCurrentPageHash();

            if (options.shouldCheckAvailability && location.includes(currentStepHash)) {
                // check if it's inside location finder
                const { pickupLocation, returnLocation } = gmaInitiateLocationsSelector(state);

                const gmaPickupTime = getInitiatePickupTime(state);
                const uiPickupTime = getUiInitiatePickupTime(state);
                const gmaReturnTime = getInitiateReturnTime(state);
                const uiReturnTime = getUiInitiateReturnTime(state);

                const isUiOneWay = getUiOneWayRentalFlag(state);
                const isOneWayRes = getOneWayRentalResFlow(state);

                const userIsOnMapPickup = currentStepHash === location[0];
                const pickupLocationChanged = uiPickupLocation.id !== pickupLocation.id;
                const returnLocationChanged = uiReturnLocation.id !== returnLocation.id;
                const pickupTimeChanged = uiPickupTime !== gmaPickupTime;
                const returnTimeChanged = uiReturnTime !== gmaReturnTime;

                const isOneWayAndReturnLocationChanged =
                    isOneWayRes && userIsOnMapPickup && !pickupLocationChanged && returnLocationChanged;
                const changedToOneWayWithNonCityReturn =
                    !isOneWayRes && isUiOneWay && uiReturnLocation.type !== LOCATIONS.TYPE_CITY;
                const pickupAndReturnNotChanged = !pickupLocationChanged && !returnLocationChanged;

                // If it's one way reservation on map_pickup, and the dropoff location or date/time changed, should update availability
                // If one way reservation and location changed, we are already checking vehicles availability from action/locationFinder/294
                if (
                    isOneWayAndReturnLocationChanged ||
                    changedToOneWayWithNonCityReturn ||
                    pickupAndReturnNotChanged ||
                    pickupTimeChanged ||
                    returnTimeChanged
                ) {
                    shouldUpdateAvailability = true;
                }
            }

            if (options.preserveBundleProfile) {
                const bundle_profile = state.getIn(['gmi', 'session', 'gma', 'initiate_request', 'bundle_profile']);
                bundle_profile && dispatch(initiateState.set('bundle_profile', { bundle_profile }));
            }

            return dispatch(
                gmiRequest({
                    action: initiate(null, null, options.freshSession ? { headers: { SOFRESH: 'SOCLEAN' } } : {}),
                    loadingOverlay: true,
                    onComplete: (response) => {
                        if (!response.messages) {
                            const { saveRecentSearches, saveLatestSelections, shouldCheckAvailability } = options;

                            if (saveRecentSearches) {
                                uiPickupLocation && dispatch(setRecentSearchItem(uiPickupLocation));
                                uiReturnLocation && dispatch(setRecentSearchItem(uiReturnLocation));
                            }

                            // store the reservation now in case it is later abandoned
                            saveLatestSelections && utils.localStorage.storeReservationLocalStorage(state);

                            // check vehicles availability for locations
                            if (shouldCheckAvailability && shouldUpdateAvailability) {
                                const locationFinderData = locationFinderDataSelector(state);
                                const storeKey = 'location_finder';
                                dispatch(getLocationsAvailability(locationFinderData, storeKey, true));
                            }
                            dispatch(setProgressBarLocationInitiateState(true));
                        } else {
                            // Update GMA info since initiate didn't return data
                            dispatch(fetchCurrentSession());
                            dispatch(setProgressBarLocationInitiateState(false));
                        }

                        // Storing the boolean value if res flow initiated in TrueModify flow
                        if (isTrueModify) {
                            utils.storageManager.SessionStorage.set(SESSION_STORAGE.INITIATE_RES_FLOW_TRUE_MODIFY, true);
                        }

                        return response;
                    },
                })
            );
        };