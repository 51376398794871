export const appReservationPath = ['app', 'reservation'];
export const appLocationFinderPath = ['app', 'location_finder'];
export const appVehiclesListPath = ['app', 'vehicles_list'];
export const appUnauthReservationPath = ['app', 'unauth_reservation'];
export const appVehiclesLocationsPath = [...appVehiclesListPath, 'vehicles_availability'];
export const countryPath = ['app', 'rentalSearch'];

// Reservation Paths
export const appProgressBarPath = [...appReservationPath, 'progressBar'];
export const appExpandedTabPath = [...appProgressBarPath, 'expandedTab'];
export const appMobileExpandedPath = [...appProgressBarPath, 'mobileExpanded'];
export const appTotalDropDownExpandedTabPath = [...appProgressBarPath, 'totalDropDownExpanded'];
export const appDiscountCodesExpandedTabPath = [...appProgressBarPath, 'discountCodesExpanded'];
export const appShowAdditionalDriverIsSpousePath = [...appReservationPath, 'additionalDriverIsSpouse'];
export const appInResCheckInSaveTimePath = [...appReservationPath, 'inResCheckInSaveTimeSelected'];
export const appReservationSessionLDTPath = [...appReservationPath, 'sessionLDT'];
export const countrySelectedPath = [...countryPath, 'countrySelected'];

// path to verify the progress bar initiate success or failure
export const appProgressBarLocationInitiatePath = [...appProgressBarPath, 'progressBarLocationInitiate'];

// Location Finder Paths
export const appLastValidSearchedLocationPath = [...appLocationFinderPath, 'lastValidSearchedLocation'];
export const appShowSoldOutNerabyLocationsPath = [...appLocationFinderPath, 'showSoldOutNearbyLocations'];
export const appShowingPartnerLocationsPath = [...appLocationFinderPath, 'showingPartnerLocations'];
export const appLocationFinderResultPath = [...appLocationFinderPath, 'result'];
export const appSelectedLocationPath = [...appLocationFinderPath, 'selected_location'];
export const appLocationFinderBrandsPath = [...appLocationFinderPath, 'brands_in_result'];
export const appLocationFinderTimestampPath = [...appLocationFinderPath, 'timestamp'];
export const appAdjustLocationIdPath = [...appLocationFinderPath, 'adjustLocationId'];
export const updatedSoldOutDateTimePath = [...appLocationFinderPath, 'updatedSoldOutDateTime'];
export const soldOutAreaPath = [...appLocationFinderPath, 'soldOutArea'];
export const skipAnalyticsLocationFinderPath = [...appLocationFinderPath, 'skipAnalytics'];

// Vehicle Lists Paths

export const appVehicleFiltersPath = [...appVehiclesListPath, 'filters'];
export const appVehicleCurrencyTogglePath = [...appVehiclesListPath, 'currency_toggle'];

export const vehicleLocationsAvailabilityPath = [...appVehiclesLocationsPath, 'car_class_availability'];
