import modal from './modal';
import locationFinder from './locationFinder';
import vehicleGrid from './vehicleGrid';
import locationSearch from './locationSearch';
import reservation from './reservation';
import vehicleClass from './vehicleClass';
import associate from './associate';
import uiState from './uiState';
import rentalSearch from './rentalSearch';
import cid from './cid';

// Only setting reducers that are essential globally - the rest should be initialized by `combineReducers` in controllers/containers of the components
export const initialReducers = [
  modal.reducer,
  locationFinder.reducer,
  locationSearch.reducer,
  reservation.reducer,
  vehicleGrid.reducer,
  vehicleClass.reducer,
  associate.reducer,
  uiState.reducer,
  rentalSearch.reducer,
  cid.reducer,
];

export const initialState = {
  modal: modal.initialState,
  location_finder: locationFinder.initialState,
  vehicle_grid: vehicleGrid.initialState,
  location_search: locationSearch.initialState,
  reservation: reservation.initialState,
  vehicles_list: vehicleClass.initialState,
  unauth_reservation: associate.initialState,
  uiState: uiState.initialState,
  rentalSearch: rentalSearch.initialState,
  cid: cid.initialState,
};
